<!-- Normal view start-->
<div *ngIf="display">
<mat-toolbar class="primary" fxHide fxShow.gt-md fxLayout="row" >
    <mat-toolbar-row style="height: 100%;" class="theme-container"  fxLayoutAlign="space-between center">
    <section>
      <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

    </section>
    <section>
        <div class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="start center" fxFlex
            fxFlex.gt-sm="500px">
            <calsoft-search></calsoft-search>
        </div>
    </section>
    <section>
       
    </section>
    <section fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
        <calsoft-call [data]="data"></calsoft-call>
        <div *ngIf="languageSelection==1">
            <calsoft-language  style="color: #ffffff"></calsoft-language>
        </div>

        <div *ngIf="data.disableLoginHeader==0">
        <button *ngIf="!userLoggedIn" (click)="SignIn('login')" style="line-height: 15px" mat-button>
            <div style="color: #ffffff" fxLayout="column">
                <small>Hello, Sign in</small>
                <span>Account & Lists</span>
            </div>
        </button>
        </div>
        <calsoft-HeaderUserProfileDropdown [data]="data" class="headerUser" *ngIf="userLoggedIn">
        </calsoft-HeaderUserProfileDropdown>

        <calsoft-cart-icon [color]="false" ></calsoft-cart-icon>
    </section>
</mat-toolbar-row>
</mat-toolbar>
<mat-toolbar class="primary"   fxHide  fxShow.gt-md fxLayout="row" fxLayoutAlign="center center"
    style="padding: 0px;color: #ffffff;height: -webkit-fill-available">
    <mat-toolbar-row class="theme-container"  fxLayoutAlign="center center">
    <calsoft-Menu [color]="true" [data]="data" [type]="'two'" style="z-index: 1000;" [categoryArray]="item"></calsoft-Menu>
    </mat-toolbar-row>
</mat-toolbar>


<mat-toolbar style="z-index: 1000;" fxHide fxShow.md fxShow.gt-md class="calsoft-fixed-header primary" fxLayout="row" fxLayoutAlign="space-between center">
    <mat-toolbar-row class="theme-container" style="height: 100%;" fxLayoutAlign="space-between center">
    <section>
        <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

    </section>
    <section>
        <div class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="start center" fxFlex
            fxFlex.gt-sm="500px">
            <calsoft-search></calsoft-search>
        </div>
    </section>
    <section fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
        <calsoft-language [type]="scroll" *ngIf="languageSelection==1" style="color: #ffffff"></calsoft-language>
        <div *ngIf="data.disableLoginHeader==0">
        <button *ngIf="!userLoggedIn" (click)="SignIn('login')" style="line-height: 15px" mat-button>
            <div style="color: #ffffff" fxLayout="column">
                <small>Hello, Sign in</small>
                <span>Account & Lists</span>
            </div>
        </button>
        </div>
        <calsoft-HeaderUserProfileDropdown [data]="data"  [type]="scroll" class="headerUser" *ngIf="userLoggedIn">
        </calsoft-HeaderUserProfileDropdown>
        <calsoft-cart-icon [color]="false" ></calsoft-cart-icon>
    </section>
    </mat-toolbar-row>

</mat-toolbar>

</div>
<div *ngIf="!display">
    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
        
        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
        
        </calsoft-mobile-header-new>
    </div>
    
</div>

<!-- <calsoft-mobile-header-fixed [url]="url" [userLoggedIn]="userLoggedIn">
  
</calsoft-mobile-header-fixed> -->
