<div *ngIf="display">
<mat-toolbar class="primary"  fxHide fxShow.gt-md>
    <mat-toolbar-row class="theme-container" style="background: rgb(0, 0, 0);padding: 0px;color: #ffffff;height: 40px" fxLayout="row"
        fxLayoutAlign="end center" fxLayoutGap="5px">
      

        <!-- <div  fxLayout="row"  fxLayoutAlign="center center">
            <a href="tel:+11234567890" class="phone-btn"><mat-icon >
                call 
              </mat-icon>     +1 123 456 7890</a>
        </div> -->

        <calsoft-call [data]="data"></calsoft-call>

     
        <div *ngIf="languageSelection==1">
            <calsoft-language style="color: #ffffff"></calsoft-language>
        </div>
        <!-- <button *ngIf="(url!='/cart' && url!='/checkout'&& url!='/checkout/type')"  (click)="cart()" mat-button class="flex-row-button">
            <mat-icon>add_shopping_cart</mat-icon>
            <span *ngIf="userLoggedIn && cartServices.cartCount != 0" class="cart-items-count">
                {{ cartServices.cartCount }}</span>
        </button> -->
        <calsoft-cart-icon [color]="false" ></calsoft-cart-icon>
        <div *ngIf="url!='/account/wishlist'&& userLoggedIn">
            <button (click)="wishlist()" mat-icon-button matBadgePosition="after" matBadgeColor="accent">
                <i class="material-icons notranslate">
                    favorite_border
                </i>
            </button>
        </div>
        <div *ngIf="data.disableLoginHeader==0">
        <div *ngIf="!userLoggedIn" class="_sadh" (click)="SignIn('login')" fxLayoutAlign="start center">
            <button mat-button class="w-color" *ngIf="!userLoggedIn">LOGIN/SIGNUP
            </button>
        </div>
        </div>

        <calsoft-HeaderUserProfileDropdown [data]="data"  class="headerUser" *ngIf="userLoggedIn">
        </calsoft-HeaderUserProfileDropdown>
    </mat-toolbar-row>
    <mat-toolbar-row  class="theme-container" style="background: #ffffff; height: 100%;" fxLayout="row" fxLayoutAlign="space-between center">
        <section>
            <calsoft-logo  [sitename]="data.siteName" [type]="'light'" ></calsoft-logo>
        </section>

        <div fxLayout="row" fxLayoutAlign="end center">
            <calsoft-search fxFlex="500px"></calsoft-search>

        </div>
    </mat-toolbar-row> 
    <mat-toolbar-row  class="primary theme-container"  style="padding: 0px ;color: #ffffff;height: -webkit-fill-available" fxLayout="row"
        fxLayoutAlign="center center">
        <calsoft-Menu [color]="true" [data]="data" [type]="'two'" style="z-index: 1000;"  class="menu" [categoryArray]="item"></calsoft-Menu>

    </mat-toolbar-row>
</mat-toolbar>

<mat-toolbar style="z-index: 1000;" fxHide fxShow.gt-md fxShow.md  class="calsoft-fixed-header primary" fxLayout="row"
  >
    <mat-toolbar-row class="theme-container" style="height: 100%;"  fxLayoutAlign="space-between center">
    <section>
        <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
    </section>
    <div class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="start center" fxFlex
        fxFlex.gt-sm="500px">
        <calsoft-search></calsoft-search>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
        <div *ngIf="data.disableLoginHeader==0">
        <button style="color:#ffffff" *ngIf="!userLoggedIn" (click)="SignIn('login')" mat-button>
            Login/Signup
        </button>
        </div>
        <calsoft-HeaderUserProfileDropdown [data]="data" [type]="scroll" class="headerUser" *ngIf="userLoggedIn">
        </calsoft-HeaderUserProfileDropdown>
        <calsoft-cart-icon [color]="false"></calsoft-cart-icon>
    </div>
    </mat-toolbar-row>
</mat-toolbar>
</div>
<div *ngIf="!display">
    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
        
        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
        
        </calsoft-mobile-header-new>
    </div>

</div>


<!-- Mobile View  end -->
