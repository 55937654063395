<div *ngIf="display">
    <mat-toolbar-row class="theme-container" style="background: rgb(0, 0, 0);padding: 0px;color: #ffffff;height: 40px"
        fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">

        <calsoft-call [data]="data"></calsoft-call>

        <div *ngIf="languageSelection==1">
            <calsoft-language style="color: #ffffff"></calsoft-language>
        </div>

        <calsoft-cart-icon [color]="false"></calsoft-cart-icon>

        <div *ngIf="url!='/account/wishlist'&& userLoggedIn">
            <button (click)="wishlist()" mat-icon-button matBadgePosition="after" matBadgeColor="accent">
                <i class="material-icons notranslate">
                    favorite_border
                </i>
            </button>
        </div>

        <div *ngIf="data.disableLoginHeader==0">
        <div *ngIf="!userLoggedIn" class="_sadh" (click)="SignIn('login')" fxLayoutAlign="start center">
            <button mat-button class="w-color" *ngIf="!userLoggedIn">LOGIN/SIGNUP
            </button>
        </div>
        </div>

        <calsoft-HeaderUserProfileDropdown [data]="data" class="headerUser" *ngIf="userLoggedIn">
        </calsoft-HeaderUserProfileDropdown>
    </mat-toolbar-row>
    <div *ngIf="url!='/home'">
        <mat-toolbar-row fxLayoutAlign="start center" style="height: auto;">

            <div fxFlex="5">

            </div>
            <div fxLayout="row" fxFlex="20" fxLayoutGap="10px">
                <section class="padding-10">

                    <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

                </section>
            </div>

            <div fxLayout="row" fxLayoutGap="10px" fxFlex="65">

                <calsoft-Menu [color]="false" [data]="data" [type]="'one'" style="color: #000000; z-index: 1000;"
                    class="menu" [categoryArray]="item"></calsoft-Menu>

            </div>

            <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="end center">
                <div *ngIf="url != '/cart'  && url !== '/checkout' && url !=='/checkout/type'">
                    <mat-icon style=" cursor: pointer;" fxLayoutAlign="center center" *ngIf="!displaySearchBar"
                        (click)="showSearchBar()">search</mat-icon>
                </div>


                <div *ngIf="displaySearchBar" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
                    fxLayoutAlign="start center" fxFlex fxFlex.gt-sm="350px">
                    <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                        [searchbarborderRadius]="'15px'"></calsoft-search>
                </div>

            </div>

            <div fxFlex="5">

            </div>

            <!-- <div fxLayoutAlign="end center" fxFlex="10"
                *ngIf="url !== '/cart' && url !== '/checkout' && url !=='/checkout/type' ">

                <button (click)="wishlist()" mat-button *ngIf="url != '/account/wishlist'">
                    <mat-icon>favorite_border</mat-icon>

                </button>

                <calsoft-cart-icon [color]="true" fxFlex></calsoft-cart-icon>

            </div> -->
        </mat-toolbar-row>
    </div>

    <div *ngIf="url=='/home'" class="header">
        <div class="header-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="logo">
                <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

            </div>
            <div fxLayout="row">
                <mat-toolbar-row fxHide fxShow.gt-sm class="container" fxLayout="row" fxLayout.xs="row"
                    fxLayoutAlign="space-between center">
                    <calsoft-Menu [color]="true" [data]="data" [type]="'one'" style="color: #000000;" class="menu"
                        [categoryArray]="item"></calsoft-Menu>
                </mat-toolbar-row>

                <!-- <calsoft-cart-icon [color]="true" fxFlex></calsoft-cart-icon> -->
            </div>

        </div>
    </div>

    <mat-toolbar style="z-index: 1000;" fxHide fxShow.gt-md fxShow.md class="calsoft-fixed-header primary"
        fxLayout="row">
        <mat-toolbar-row class="theme-container" style="height: 100%;" fxLayoutAlign="space-between center">
            <section>
                <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
            </section>
            <div class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="start center" fxFlex
                fxFlex.gt-sm="500px">
                <calsoft-search></calsoft-search>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
                <button style="color:#ffffff" *ngIf="!userLoggedIn" (click)="SignIn('login')" mat-button>
                    Login/Signup
                </button>
                <calsoft-HeaderUserProfileDropdown [data]="data" [type]="scroll" class="headerUser"
                    *ngIf="userLoggedIn">
                </calsoft-HeaderUserProfileDropdown>
                <calsoft-cart-icon [color]="false"></calsoft-cart-icon>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>

</div>

<div *ngIf="!display">
    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>
</div>